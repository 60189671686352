import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/bgimg.jpg');

class PostImage extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content ">
                <Banner title="Putting a plan to action, to assure your satisfaction!." pagename="Post Image" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                        <div className="blog-single-wrap-width">
                            <div className="blog-post date-style-3 blog-detail text-black">                               
                                <div className="mt-post-meta ">
                                    <ul>
                                        <li className="post-date"><strong>19 </strong> <span>Feb 2021</span> </li>
                                        <li className="post-author"><NavLink to={"#"}>By <span>Admin</span></NavLink> </li>
                                        <li className="post-category"><NavLink to={"#"}><span>Traditional</span></NavLink> </li>
                                    </ul>
                                </div>
                                <div className="mt-post-title ">
                                    <h3 className="post-title"><NavLink to={"#"} className="font-weight-600">Operation & Maintenance (O & M) – A key to optimum Solar Power Generation</NavLink></h3>
                                </div>
                                <div className="mt-post-text">
                                    <p>In the world dominated by few industries, one of the growing and promising industries is Renewable Energy industry as it is the need of the hour. Considering the carbon footprint, everyone is thriving hard to create new solutions towards a greener and pollution free environment.
Renewable Energy comes in many forms like Bio-fuels, Wind, Solar etc. But many are getting attracted towards Solar Renewable as the abundance of energy stored in Sun and hence, huge investment is pouring in to cash in on that energy.</p>
                                    
                                    <p>Solar panel installation is an excellent way to provide the cheapest form of electricity to people particularly in developing countries with huge solar energy reservoir. Solar panels have a lifespan of roughly 30 years and require utmost maintenance. However, there are problems associated with its Operation and Maintenance (O&M) and can be overcome by effective O & M services.</p>
                                    <p>O & M is the most critical aspect of Solar plant as it ensures expected power generation as guaranteed to customers. O & M of the plant requires skillful planning as it plays a vital role in meeting the client requirement and ensures safety of the plant. O & M enhances plant reliability, efficiency and helps achieve customer satisfaction. It’s constant monitoring & preventative mechanism helps in timely identifying & resolving the technical snags thus keeps the plant healthy and ensures optimal performance.</p>
                                    <p>Every service industry faces many challenges when it comes to providing quality services. O & M is that service part of the Solar Industry which ensures efficient working of the plant, mitigates risk and improves equipment longevity and performance. O & M acts as a conduit in Solar business as it helps the organization create a brand name in the market and grow successfully.</p>
                                    <p>Many Solar companies take pride in their O & M services as it offers a win-win mix of most cost-effective practices; keeping the organization afloat and motivated. O & M solutions being customized and plant-specific makes the customer “feel special” and hence is at the heart of the Solar industry.</p>

                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="mt-box m-b30">
                                                <div className="mt-media">
                                                    <img src={require("./../../images/blog/Blog1.jpeg")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="mt-box m-b30">
                                                <div className="mt-media">
                                                    <img src={require("./../../images/blog/Blog12.jpeg")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="mt-box m-b30">
                                                <div className="mt-media">
                                                    <img src={require("./../../images/blog/Blog13.jpeg")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="autor-post-tag-share bdr-t-1 bdr-solid bdr-gray p-t20">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="widget widget_tag_cloud">
                                                <h4 className="tagcloud">Tags</h4>
                                                <div className="tagcloud">
                                                    <NavLink to={"#"}>First tag</NavLink>&nbsp;
                                                    <NavLink to={"#"}>Second tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Three tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Four tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Five tag</NavLink> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="mt-box">
                                                <div className="clearfix">
                                                    <h4>Share this Post:</h4>
                                                    <div className="widget_social_inks">
                                                        <ul className="social-icons social-md social-square social-dark m-b0">
                                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-rss" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                       
                            {/* <BlogComments/> */}
                        </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default PostImage;